import React, { Component } from 'react';
import {ListItem, Dialog, List, ListItemAvatar, ListItemSecondaryAction, Avatar, DialogActions, CircularProgress} from '@material-ui/core';
import {ListItemText,Typography, IconButton, Grid, Box, DialogTitle, DialogContent, Icon, Backdrop} from '@material-ui/core';
import {ChevronLeft, ChevronRight, Repeat, MoreHoriz} from '@material-ui/icons/';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import {AddTransacaoDialog} from '../transacoes/AddTransacaoDialog'

import axios from 'axios';
import { DateTime } from "luxon";

import '../css/Generic.css'
import {Utils} from '../generic/Utils';

export class ExtratoCartao extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selected: '',
            fatura: { movimentacoes: [] },
            data: DateTime.now(),
            valorTotal: 0,
            loading: true
        }  

        this.handleClose = this.handleClose.bind(this)
        this.onRendered = this.onRendered.bind(this)
        this.buscarMovimentacoes = this.buscarMovimentacoes.bind(this)
    }

    handleClose() {        
        this.props.onClose()
        this.setState({data: DateTime.now()})
    }

    onRendered() {
        this.setState({data: DateTime.now()})
        this.setState({categoriasReceita: JSON.parse(sessionStorage.getItem('categoriasReceita'))})
        this.setState({categoriasDespesa: JSON.parse(sessionStorage.getItem('categoriasDespesa'))})
        this.buscarMovimentacoes(this.state.data) 
    }

    getLogo(icon) {
        if ( icon === '')
            return  <Avatar style={{ width: 55, height: 55 }} variant="rounded">                                
                        <CreditCardIcon /> 
                    </Avatar>
        else
            return <Avatar src={icon} style={{ width: 55, height: 55 }} variant="rounded"/>
    }

    buscarMovimentacoes(d) {
        let cartaoId = this.props.cartao._id
        let user = sessionStorage.getItem('userId')  

        let m = d.month
        if ( m<10 ) m = "0"+m
        let dia = d.day
        if ( dia<10 ) dia = "0"+dia
        let datestring = d.year + "-" + m + "-" + dia      

        this.setState({loading: true})
        axios.get(Utils.getUrl()+"cartoes/user/"+user+"/fatura/"+cartaoId+"/date/"+datestring).then(
            resp => {
                if ( resp.status === 201 || resp.status === 200) {
                    let fatura = resp.data

                    if ( !fatura.movimentacoes ) {
                        this.setState({fatura: fatura, valorTotal: 0})
                        this.setState({loading: false})
                        return
                    }

                    let listaMovimentacoes = fatura.movimentacoes

                    listaMovimentacoes.forEach(e => {

                        e.valorExibicao = e.valor

                        if ( e.recorrente ) {
                            if ( e.detalheMovimentacaoCartao === null ) e.detalheMovimentacaoCartao = []
                            for(let i=0; i<e.detalheMovimentacaoCartao.length; i++) {

                                if ( e.detalheMovimentacaoCartao[i].mes === d.month &&
                                    e.detalheMovimentacaoCartao[i].ano === d.year ) {

                                    e.descricao = e.descricao + ' ('+(i+1)+'/'+e.detalheMovimentacaoCartao.length+')'
                                        
                                    e.valorExibicao = e.detalheMovimentacaoCartao[i].valor
                                                                                                        
                                    break
                                }

                            }  
                        }

                    });

                    fatura.movimentacoes = listaMovimentacoes;
                    
                    this.setState({fatura: fatura, valorTotal: fatura.valorTotal})
                }
                else console.log("Erro ao buscar fatura")

                this.setState({loading: false})
            }
        ).catch( e => {
            console.log("Erro ao buscar fatura "+e) 
            this.setState({loading: false})
        })
    }

    getAvatar(e) {
        let cor = ''
        let icon = ''
        let descricao = ''

        for (var i=0; i<this.state.categoriasReceita.length; i++) {
            if ( this.state.categoriasReceita[i]._id === e.idCategoria ) {
                cor = this.state.categoriasReceita[i].cor
                icon = this.state.categoriasReceita[i].icone
                descricao = this.state.categoriasReceita[i].descricao
            }
        }

        for (i=0; i<this.state.categoriasDespesa.length; i++) {
            if ( this.state.categoriasDespesa[i]._id === e.idCategoria ) {
                cor = this.state.categoriasDespesa[i].cor
                icon = this.state.categoriasDespesa[i].icone
                descricao = this.state.categoriasDespesa[i].descricao
            }
        }

        return (<Avatar alt={descricao}
            style={{
                backgroundColor: cor,
            }} 
            variant="rounded">
                <Icon>{icon}</Icon>
        </Avatar>)
    }

    render() {
        return (
            <Dialog 
                fullWidth
                scroll="paper"
                open={this.props.open}
                onClose={this.handleClose}
                onRendered={this.onRendered}>

                <DialogTitle id="scroll-dialog-title">     
                
                    <Grid container justifyContent="center" >             
                        {this.getLogo(this.props.cartao.icone)}
                    </Grid>
                    <Grid container justifyContent="center" > 
                        <Typography style={{textTransform:'capitalize'}}>
                            <IconButton onClick={ () => {
                                    let m = this.state.data.minus({ month: 1 })
                                    this.setState({data: m}) 
                                    this.buscarMovimentacoes(m)
                                }}>
                                <ChevronLeft />
                            </IconButton>
                            
                            {this.state.data.setLocale('pt').toFormat('MMMM yyyy') }
                            
                            <IconButton onClick={ () => {
                                    let m = this.state.data.plus({ month: 1 })
                                    this.setState({data: m}) 
                                    this.buscarMovimentacoes(m)
                                }}>
                                <ChevronRight />
                            </IconButton>
                        </Typography>
                    </Grid>

                </DialogTitle>

                <DialogContent dividers={true}>
                    <Box minHeight="500px" textAlign={'center'}> 

                        <List className="fullSizeList">
                        {
                            !this.state.fatura.movimentacoes ? 'Sem Movimentações' : this.state.fatura.movimentacoes.map( (e) => 
                                <ListItem>
                                    <ListItemAvatar>
                                        {this.getAvatar(e)}
                                    </ListItemAvatar>
                                    
                                    <ListItemText 
                                        primary={e.descricao} 
                                        secondary={DateTime.fromISO(e.data).setLocale('pt').toFormat("dd/MM/yyyy")}
                                        onClick={() => {
                                            this.setState({selected: e, showEditDialog: true})
                                        }}
                                        style={{ cursor: 'pointer' }}/>
                                    
                                    <ListItemSecondaryAction style={{minWidth: '200px', textAlign: 'right'}}> 

                                        <Grid container justifyContent="center" alignItems="flex-start">
                                            
                                            <Grid item xs={1} hidden={!e.fixo}>
                                                <Repeat color='disabled' aria-label="Lançamento Fixo"/>
                                            </Grid>   
                                            <Grid item xs={1} hidden={!e.parcelado}>
                                                <MoreHoriz color='disabled' alt="Lançamento Parcelado"/>
                                            </Grid>     
                                            <Grid item xs={1} hidden={e.recorrente}>
                                            </Grid>


                                            <Grid item xs={8}>
                                                <Typography variant='subtitle2'>
                                                    {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(e.valorExibicao)}
                                                </Typography>
                                            </Grid>    
                                        </Grid>   

                                    </ListItemSecondaryAction>
                                </ListItem>
                            )
                        }
                        </List>
                    </Box>
                </DialogContent>

                <DialogActions>                         
                    <Typography variant='subtitle2'>
                        Total: {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.state.valorTotal)}
                    </Typography>
                </DialogActions>

                <AddTransacaoDialog open={this.state.showEditDialog} 
                    movimentacao={this.state.selected}
                    mes={this.state.data}
                    tipo="Despesa"
                    onSave={ () => {                       
                        this.buscarMovimentacoes(this.state.data)
                        this.setState({showEditDialog:false})
                        } }
                    onEdit={ () => {                       
                        this.buscarMovimentacoes(this.state.data)
                        this.setState({showEditDialog:false})
                        } }
                    onClose={ () => {
                        this.setState({showEditDialog:false})                        
                        } } 
                    onDelete={ () => {                        
                        this.buscarMovimentacoes(this.state.data)
                        this.setState({showEditDialog:false})                      
                } }/> 

                <Backdrop
                    style={{ color: '#fff', zIndex: 10000 }}
                    open={this.state.loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Dialog>
            
        )
    }
}