import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import {AccountBalanceWallet, CreditCard, ImportExport, Category} from '@material-ui/icons/';

export const mainListItems = (
  <div>
  <div>
    <ListItem button component="a" href="/#/">
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Início" />
    </ListItem>

    <ListItem button component="a" href="/#/transacoes">
      <ListItemIcon>
        <ImportExport />
      </ListItemIcon>
      <ListItemText primary="Transações" />
    </ListItem>

    <ListItem button component="a" href="/#/categorias">
      <ListItemIcon>
        <Category />
      </ListItemIcon>
      <ListItemText primary="Categorias" onClick=""/>
    </ListItem>

    <ListItem button component="a" href="/#/contas">
      <ListItemIcon>
        <AccountBalanceWallet />
      </ListItemIcon>
      <ListItemText primary="Contas" onClick=""/>
    </ListItem>

    <ListItem button component="a" href="/#/cartoes">
      <ListItemIcon>
        <CreditCard />
      </ListItemIcon>
      <ListItemText primary="Cartões" onClick=""/>
    </ListItem>

  </div>
  
  </div>
);
