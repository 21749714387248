import React, { Component } from 'react';
import {Paper, Grid, IconButton, Typography, List, ListItem, Icon, CircularProgress} from '@material-ui/core/';
import {Avatar, ListItemText, ListItemAvatar, ListItemSecondaryAction, Backdrop, Divider} from '@material-ui/core/';
import {Snackbar, Box, Hidden, BottomNavigation, BottomNavigationAction} from '@material-ui/core/';
import Skeleton from '@material-ui/lab/Skeleton';
import MuiAlert from '@material-ui/lab/Alert';
import {Add, ChevronLeft, ChevronRight, Remove, CompareArrows, ThumbDown, ThumbUp, Repeat, MoreHoriz, CreditCard} from '@material-ui/icons/';
import { AddTransacaoDialog } from './AddTransacaoDialog';

import {Utils} from '../generic/Utils';
import axios from 'axios';
import { DateTime } from "luxon";

export class Transacoes extends Component {

    constructor(props) {
        super(props)

        this.state = {
            selected: '',
            loading: true,
            loadingCategoria: true,
            listaMovimentacoes: [],
            listaCategorias: [],
            listaCartoes: [],
            listaContas: [],
            listaDias: [],
            showMessage: false,
            showErroMessage: false,
            showNovoDialog: false,
            showDeleteMessage: false,
            showEditMessage: false,
            mes: DateTime.now(),
            dialogTipo: 'Receita',
            receitaGeral: 0,
            despesaGeral: 0,
            userId: sessionStorage.getItem('userId')
        }  

        this.buscarMovimentacoes = this.buscarMovimentacoes.bind(this)
        this.isPago = this.isPago.bind(this)
    }

    buscarMovimentacoes(d) {  
        let userId = this.state.userId

        this.setState({loading: true})    

        let m = d.month
        if ( m<10 ) m = "0"+m

        let dia = d.day
        if ( dia<10 ) dia = "0"+dia

        let datestring = d.year + "-" + m + "-" + dia        

        axios.get(Utils.getUrl()+"movimentacoes/user/"+userId+"/date/"+datestring).then(
            resp => {
                if ( resp.status === 201 || resp.status === 200) {

                    let listaMovimentacoes = resp.data
                    let dias = []

                    listaMovimentacoes.forEach(e => {

                        e.valorExibicao = e.valor

                        if ( e.recorrente ) {
                            var achou = false
                            if ( e.detalheMovimentacao === null ) e.detalheMovimentacao = []
                            

                            for(let i=0; i<e.detalheMovimentacao.length; i++) {

                                if ( e.detalheMovimentacao[i].mes === this.state.mes.month &&
                                    e.detalheMovimentacao[i].ano === this.state.mes.year ) {
                                        
                                    e.valorExibicao = e.detalheMovimentacao[i].valor
                                    
                                    if ( e.detalheMovimentacao[i].idConta ) e.idConta = e.detalheMovimentacao[i].idConta
                                    else e.detalheMovimentacao[i].idConta = e.idConta
                                    
                                    achou = true

                                    let dia = new Date(e.detalheMovimentacao[i].data).getDate()
                                    if ( !dias.includes(dia) ) dias.push(dia)
                                    break
                                }

                            }  
                            

                            if (!achou && e.fixo) {
                                if ( !dias.includes(e.dia) ) dias.push(e.dia)
                                let mt = this.state.mes.month
                                let yr = this.state.mes.year

                                e.detalheMovimentacao.push(
                                    {
                                        mes: mt,
                                        ano: yr,
                                        data: new Date(yr,(mt-1),e.dia),
                                        valor: e.valor,
                                        pago: false,
                                        idConta: e.idConta,
                                        ativo: true
                                    }
                                )
                            }

                        }
                        else {
                            if ( !dias.includes(e.dia) ) dias.push(e.dia)
                        }

                    });

                    dias = dias.sort( (a,b) => Number.parseInt(a) - Number.parseInt(b) )
                    this.setState({listaMovimentacoes: listaMovimentacoes, listaDias: dias})
                } 
                else console.log("Erro ao buscar lista")

                this.setState({loading: false})
            }
        ).catch( e => {
            console.log("Erro ao buscar "+e) 
            this.setState({loading: false})
        })
    }

    componentDidMount() {        
        this.setState({listaCartoes: JSON.parse(sessionStorage.getItem('listaCartoes'))})
        this.setState({listaContas: JSON.parse(sessionStorage.getItem('listaContas'))})
        this.setState({categoriasReceita: JSON.parse(sessionStorage.getItem('categoriasReceita'))})
        this.setState({categoriasDespesa: JSON.parse(sessionStorage.getItem('categoriasDespesa'))})
        
        this.buscarMovimentacoes(this.state.mes)        
    }

    getSecondary(e) {
        for (var i=0; i<this.state.listaContas.length; i++) {
            if ( this.state.listaContas[i]._id === e.idConta ) return this.state.listaContas[i].apelido
        }

        for (i=0; i<this.state.listaCartoes.length; i++) {
            if ( this.state.listaCartoes[i]._id === e.idConta ) return this.state.listaCartoes[i].apelido
        }
        
        return ""
    }

    pagar(e,value) {
        this.setState({loading: true})

        if ( e.faturaCartao ) {
            axios.put(Utils.getUrl()+"cartoes/fatura/"+e._id).then(
                resp => {
                    if ( resp.status === 201 || resp.status === 200) { 
                        e.pago = !e.pago 
                        this.setState({showEditMessage:true,loading: false})
                    }
                    else {
                        this.setState({showErroMessage:true,loading: false})
                    }
                }
            ).catch( err => {   
                console.log(err)         
                this.setState({showErroMessage:true,loading: false})
            })
        }
        else {
            if ( e.recorrente ) {
                let m = this.state.mes.month
                let y = this.state.mes.year
    
                if ( e.detalheMovimentacao == null || e.detalheMovimentacao.length === 0 ) {
                    e.detalheMovimentacao = [
                        {
                            mes: m,
                            ano: y,
                            data: new Date(y,(m-1),e.dia),
                            valor: e.valor,
                            pago: value,
                            ativo: true
                        }
                    ]
                }
                else {
                    let achou = false
                    for(let i=0; i<e.detalheMovimentacao.length; i++) {
                        if ( e.detalheMovimentacao[i].mes === m &&
                            e.detalheMovimentacao[i].ano === y ) {
    
                            e.detalheMovimentacao[i].pago = value
                            achou = true
                            break
                        }
                    }
    
                    if (!achou && e.fixo) {
                        e.detalheMovimentacao.push(
                            {
                                mes: m,
                                ano: y,
                                data: new Date(y,(m-1),e.dia),
                                valor: e.valor,
                                pago: value,
                                ativo: true
                            }
                        )
                    }
                }
            }
            else {
                e.pago = value
            }
    
            axios.put(Utils.getUrl()+"movimentacoes/",{...e}).then(
                resp => {
                    if ( resp.status === 201 || resp.status === 200) {  
                        this.setState({showEditMessage:true,loading: false})
                    }
                    else {
                        this.setState({showErroMessage:true,loading: false})
                    }
                }
            ).catch( err => {   
                console.log(err)         
                this.setState({showErroMessage:true,loading: false})
            })
        }        
    }

    isPago(e) {

        if ( e.recorrente ) {
            if ( e.detalheMovimentacao !== null && e.detalheMovimentacao.length > 0 ) {
                let m = this.state.mes.month
                let y = this.state.mes.year

                for(let i=0; i<e.detalheMovimentacao.length; i++) {
                    if ( e.detalheMovimentacao[i].mes === m &&
                        e.detalheMovimentacao[i].ano === y ) 
                        return e.detalheMovimentacao[i].pago
                }
            }
            
            return false            
        }
        return e.pago

    }

    getAvatar(e) {
        let cor = ''
        let icon = ''
        let descricao = ''

        if ( e.faturaCartao ) {
            return (<Avatar alt={e.descricao} variant="rounded">  
                <CreditCard />
            </Avatar>)
        }

        for (var i=0; i<this.state.categoriasReceita.length; i++) {
            if ( this.state.categoriasReceita[i]._id === e.idCategoria ) {
                cor = this.state.categoriasReceita[i].cor
                icon = this.state.categoriasReceita[i].icone
                descricao = this.state.categoriasReceita[i].descricao
            }
        }

        for (i=0; i<this.state.categoriasDespesa.length; i++) {
            if ( this.state.categoriasDespesa[i]._id === e.idCategoria ) {
                cor = this.state.categoriasDespesa[i].cor
                icon = this.state.categoriasDespesa[i].icone
                descricao = this.state.categoriasDespesa[i].descricao
            }
        }

        return (<Avatar alt={descricao}
            style={{
                backgroundColor: cor,
            }} 
            variant="rounded">
                <Icon>{icon}</Icon>
        </Avatar>)
    }

    montarTela() {

        let receitaMensal = 0
        let despesaMensal = 0

        return (
            <React.Fragment key={'mes'}>
            {
                this.state.listaDias.map( i => {

                    return (
                        <React.Fragment key={'dia'+i}>
                            
                            <Grid container justifyContent="center" alignItems="center">
                                <Grid item xs={1}>
                                    <Typography variant='h6'>{i<9?"0"+i:i}</Typography>
                                </Grid>                    
                                <Grid item xs={10}>
                                    <Divider/> 
                                </Grid>
                            </Grid>   

                            <List className="fullSizeList">
                            {
                                this.state.listaMovimentacoes.map( (e) => {
                                    let dia = e.dia
                                    if ( e.recorrente ) {
                                        if ( e.detalheMovimentacao !== null && e.detalheMovimentacao.length > 0 ) {
                                            let m = this.state.mes.month
                                            let y = this.state.mes.year
                            
                                            for(let i=0; i<e.detalheMovimentacao.length; i++) {
                                                if ( e.detalheMovimentacao[i].mes === m &&
                                                    e.detalheMovimentacao[i].ano === y ) {
                                                    dia = new Date(e.detalheMovimentacao[i].data).getDate()
                                                    break
                                                }
                                            }
                                        }         
                                    }

                                    if ( dia === i ) {

                                        
                                        if ( e.faturaCartao )
                                            despesaMensal += e.valorExibicao
                                        else if ( e.categoria.tipo === 'Receita' )                                             
                                            receitaMensal += e.valorExibicao
                                        else if ( e.categoria.tipo === 'Despesa' )
                                            despesaMensal += e.valorExibicao

                                        return (
                                            <ListItem key={'itemKey'+e._id}>
                                                <ListItemAvatar>
                                                    {this.getAvatar(e)}
                                                </ListItemAvatar>

                                                <ListItemText primary={e.descricao} 
                                                    secondary={this.getSecondary(e)}
                                                    onClick={() => {
                                                        this.setState({selected: e, showNovoDialog: true, dialogTipo: e.categoria.tipo})
                                                    }}
                                                    style={{ cursor: 'pointer' }}/>

                                                <ListItemSecondaryAction style={{minWidth: '500px', textAlign: 'right'}}>
                                                    <Grid container justifyContent="center" alignItems="flex-start">
                                                        
                                                        <Grid item xs={1} hidden={!e.fixo}>
                                                            <Repeat color='disabled' />
                                                        </Grid>   
                                                        <Grid item xs={1} hidden={!e.parcelado}>
                                                            <MoreHoriz color='disabled' />
                                                        </Grid>     
                                                        <Grid item xs={1} hidden={e.recorrente}>
                                                        </Grid>


                                                        <Grid item xs={8}>
                                                            <Typography variant='subtitle2'>
                                                                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(e.valorExibicao)}
                                                            </Typography>
                                                        </Grid>                    
                                                        <Grid item xs={1} style={{textAlign: 'right'}}> 
                                                            { 
                                                                this.isPago(e)?
                                                                <ThumbUp style={{ color: 'green', cursor: 'pointer' }} 
                                                                    fontSize="small" 
                                                                    onClick={ () => this.pagar(e,false)}/>:
                                                                <ThumbDown style={{ cursor: 'pointer' }}
                                                                    color="disabled" 
                                                                    fontSize="small" 
                                                                    onClick={ () => this.pagar(e,true)}/>                                                    
                                                            }
                                                        </Grid>
                                                    </Grid>    
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        )
                                    }
                                    else return ""
                                })
                                
                            }
                            </List>
                            
                            
                            <Grid container justifyContent="center" alignItems="right">                                      
                                <Grid item md={10} style={{textAlign: 'right'}} wrap='nowrap' mdUp>
                                    <Typography variant='caption' color='textSecondary'> 
                                        Saldo no dia:
                                    </Typography>  
                                </Grid>                           
                                <Grid item xs={1} style={{textAlign: 'right'}} wrap='nowrap'>
                                    <Typography variant='subtitle2' color={receitaMensal-despesaMensal>0?'primary':'secondary'}>
                                        {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(receitaMensal-despesaMensal)}
                                    </Typography>
                                </Grid>
                            </Grid>  
                        </React.Fragment> 
                    )
                })
            }
            </React.Fragment>
        )
    }

    montarEsqueleto() {
        return (
            <Grid container spacing={1} justifyContent="center" alignItems="center">
                <Grid item xs={12}>                                
                    <Skeleton variant="text" />
                </Grid>
                <Grid item xs> 
                    <Skeleton variant="rect" width={50} height={50}/>
                </Grid>
                <Grid item xs={11}> 
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                </Grid>
                <Grid item xs style={{textAlign: 'right'}}> 
                    <Skeleton variant="circle" width={20} height={20}/>
                </Grid>
                
                <Grid item xs={12}>                                
                    <Box m="2" />
                </Grid>
                <Grid item xs> 
                    <Skeleton variant="rect" width={50} height={50}/>
                </Grid>
                <Grid item xs={11}> 
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                </Grid>
                <Grid item xs style={{textAlign: 'right'}}> 
                    <Skeleton variant="circle" width={20} height={20}/>
                </Grid>
                
                <Grid item xs={12}>                                
                    <Box m="2" />
                </Grid>
                <Grid item xs> 
                    <Skeleton variant="rect" width={50} height={50}/>
                </Grid>
                <Grid item xs={11}> 
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                </Grid>
                <Grid item xs style={{textAlign: 'right'}}> 
                    <Skeleton variant="circle" width={20} height={20}/>
                </Grid>
            </Grid>
        )
    }

    render() {
        return (
            <Paper className="class-paper">   
                <Grid container spacing={3}>

                    <Grid item xs={12} md={4}>

                        <Hidden smDown>
                            <IconButton color="primary" title='Nova Receita'
                                onClick={ () => this.setState({selected: {}, showNovoDialog: true, dialogTipo: 'Receita'})}>
                                <Add/>
                            </IconButton>

                            <IconButton color="secondary" title='Nova Despesa'
                                onClick={ () => this.setState({selected: {}, showNovoDialog: true, dialogTipo: 'Despesa'})}>
                                <Remove/>
                            </IconButton>
                            
                            <IconButton variant="outlined" title='Nova Transferência'>
                                <CompareArrows />
                            </IconButton>
                        </Hidden>
                        
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <div style={{textAlign: 'center'}}>  

                            <Typography style={{textTransform:'capitalize'}}>
                                <IconButton onClick={ () => {
                                        let m = this.state.mes.minus({ month: 1 })
                                        this.setState({mes: m}) 
                                        this.buscarMovimentacoes(m)
                                    }}>
                                    <ChevronLeft />
                                </IconButton>
                                
                                {this.state.mes.setLocale('pt').toFormat('MMMM yyyy') }
                                
                                <IconButton onClick={ () => {
                                        let m = this.state.mes.plus({ month: 1 })
                                        this.setState({mes: m}) 
                                        this.buscarMovimentacoes(m)
                                    }}>
                                    <ChevronRight />
                                </IconButton>
                            </Typography>
                        </div>

                        <Hidden mdUp>
                            <BottomNavigation showLabels>
                                <BottomNavigationAction label="Receita" icon={
                                    <IconButton color="primary" title='Receita'
                                        onClick={ () => this.setState({selected: {}, showNovoDialog: true, dialogTipo: 'Receita'})}>
                                        <Add/>
                                    </IconButton>
                                } />
                                <BottomNavigationAction label="Despesa" icon={
                                    <IconButton color="secondary" title='Despesa'
                                        onClick={ () => this.setState({selected: {}, dialogTipo: 'Despesa', showNovoDialog: true})}>
                                        <Remove/>
                                    </IconButton>
                                } />
                                <BottomNavigationAction label="Transferência" icon={
                                    <IconButton variant="outlined" title='Transferência'>
                                        <CompareArrows />
                                    </IconButton>
                                } />
                            </BottomNavigation>
                        </Hidden>

                    </Grid>
                    <Grid item xs={4}>
                    </Grid>

                    <Grid item xs={12}>                        
                        {
                            !this.state.loading ? (
                                this.montarTela()
                            ) : (
                               this.montarEsqueleto()
                            )
                        }
                    </Grid>
                </Grid>  

                <AddTransacaoDialog open={this.state.showNovoDialog} 
                    movimentacao={this.state.selected}
                    mes={this.state.mes}
                    tipo={this.state.dialogTipo}
                    onSave={ () => {
                        this.buscarMovimentacoes(this.state.mes) 
                        this.setState({showNovoDialog:false, showMessage: true})
                        } }
                    onEdit={ () => {
                        this.buscarMovimentacoes(this.state.mes) 
                        this.setState({showNovoDialog:false, showEditMessage: true})
                        } }
                    onClose={ () => {
                        this.setState({showNovoDialog:false})                        
                        } } 
                    onDelete={ () => {
                        this.buscarMovimentacoes(this.state.mes) 
                        this.setState({showNovoDialog:false, showDeleteMessage: true})                        
                        } }/>     

                <Snackbar open={this.state.showMessage} autoHideDuration={6000} 
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
                    onClose={() => this.setState({showMessage:false})}>
                    <MuiAlert severity="success" elevation={6} variant="filled" 
                        onClose={() => this.setState({showMessage:false})}>
                        Movimentação cadastrada com sucesso!
                    </MuiAlert>
                </Snackbar>

                <Snackbar open={this.state.showEditMessage} autoHideDuration={6000} 
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
                    onClose={() => this.setState({showEditMessage:false})}>
                    <MuiAlert severity="success" elevation={6} variant="filled" 
                        onClose={() => this.setState({showEditMessage:false})}>
                        Movimentação atualizada com sucesso!
                    </MuiAlert>
                </Snackbar>

                <Snackbar open={this.state.showDeleteMessage} autoHideDuration={6000} 
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
                    onClose={() => this.setState({showDeleteMessage:false})}>
                    <MuiAlert severity="success" elevation={6} variant="filled" 
                        onClose={() => this.setState({showDeleteMessage:false})}>
                        Movimentação excluída com sucesso!
                    </MuiAlert>
                </Snackbar>

                
                <Snackbar open={this.state.showErroMessage} autoHideDuration={6000} 
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
                    onClose={() => this.setState({showErroMessage:false})}>
                    <MuiAlert severity="error" elevation={6} variant="filled" 
                        onClose={ () => this.setState({showErroMessage:false})}>
                        Ocorreu um erro, por favor, tente novamente.
                    </MuiAlert>
                </Snackbar>
                
                <Backdrop
                    style={{ color: '#fff', zIndex: 10000 }}
                    open={this.state.loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>           
            </Paper>
        )
    }

}