import React, { Component } from 'react';
import {Box, Grid, Link, Snackbar, Dialog, DialogTitle, TextField, Typography} from '@material-ui/core';
import {Avatar, DialogActions, DialogContent, InputAdornment, Fab} from '@material-ui/core/';
import {InputLabel, Select, MenuItem, ListSubheader, Icon, Switch} from '@material-ui/core/';
import {RadioGroup, FormControlLabel, Radio} from '@material-ui/core/';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {Backdrop, CircularProgress} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import {Check} from '@material-ui/icons/'
import {ConfirmDialog} from '../util/ConfirmDialog'

import LuxonUtils from '@date-io/luxon';
import { DateTime } from "luxon";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import {Utils} from '../generic/Utils';
import axios from 'axios';

import '../css/Generic.css'

export class AddTransacaoDialog extends Component {

    constructor(props) {
        super(props)
        this.state = {
            cadastro: {
                descricao: '',
                valor: 0,
                idConta: '',
                idCategoria: '',
                recorrencia: '',
                parcelas: '2',
                tipoRecorrenciaFixa: 'Mensal',
                data: new Date()
            },
            fatura: DateTime.now(),
            listaFatura: [],
            categoriasReceita: [],
            categoriasDespesas: [],
            listaCategoria: [],
            listaCartoes: [],
            listaContas: [],
            listaMovimentacoes: [],
            showMessage: false,
            showErroMessage:false,
            showEditMessage: false,
            confirmDelete: false,
            saving: false,
            icon: '',
            iconBgColor: '',
            atualizarProximos: false
        }

        this.handleClose = this.handleClose.bind(this)
        this.handleCloseMessage = this.handleCloseMessage.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.editFragment = this.editFragment.bind(this)

        this.onRendered = this.onRendered.bind(this)
    }

    handleClose() {
        this.setState({            
            showMessage: false,
            showErroMessage:false,
            showEditMessage: false
        })
        this.props.onClose()
    }

    handleDelete() {
        this.setState({showErroMessage:false,saving: true})

        //Deletar
        if ( this.props.movimentacao._id ) {

            if ( !this.props.movimentacao.recorrente ) {

                axios.delete(Utils.getUrl()+"movimentacoes/"+this.props.movimentacao._id).then(
                    resp => {
                        if ( resp.status === 201 || resp.status === 200) {   
                            this.setState({saving: false, confirmDelete:false})
                            this.props.onDelete()
                        }
                        else {
                            this.setState({showErroMessage:true, confirmDelete:false, saving: false})
                        }
                    }
                ).catch( e => {     
                    console.log(e)       
                    this.setState({showErroMessage:true, confirmDelete:false, saving: false})
                })
            }
            else {

                let d = this.props.mes
                let m = d.month
                if ( m<10 ) m = "0"+m

                let dia = d.day
                if ( dia<10 ) dia = "0"+dia

                let datestring = d.year + "-" + m + "-" + dia

                if ( this.props.movimentacao.recorrente ) {
                    axios.put(Utils.getUrl()+"movimentacoes/"+this.props.movimentacao._id+"/"+datestring).then(
                        resp => {
                            if ( resp.status === 201 || resp.status === 200) {   
                                this.setState({saving: false, confirmDelete:false})
                                this.props.onDelete()
                            }
                            else {
                                this.setState({showErroMessage:true, confirmDelete:false, saving: false})
                            }
                        }
                    ).catch( e => {     
                        console.log(e)       
                        this.setState({showErroMessage:true, confirmDelete:false, saving: false})
                    })
                }

            }
        }
    }

    handleSave() {

        let cadastro = {...this.state.cadastro}
        cadastro.userId = sessionStorage.getItem('userId')

        if ( cadastro.descricao === '' ||  cadastro.data === '' ) {
            this.setState({showErroMessage: true})
            return
        }

        this.setState({saving: true})
        this.setState({showErroMessage:false})

        if ( cadastro.cartao ) {
            cadastro.fatura = this.state.fatura
        }

        if ( this.props.movimentacao._id ) { //Edição            

            if ( this.props.movimentacao.recorrente ) {

                let m = this.props.mes.month
                let y = this.props.mes.year

                let achou = false
                for(let i=0; i<cadastro.detalheMovimentacao.length; i++) {
                    if ( cadastro.detalheMovimentacao[i].mes === m &&
                        cadastro.detalheMovimentacao[i].ano === y ) { 
                        achou = true

                        if ( this.props.movimentacao.parcelado && this.state.atualizarProximos ) {
                            //Atualizar movimentacoes futuras
                            for (let j=i; j<cadastro.detalheMovimentacao.length; j++) {
                                cadastro.detalheMovimentacao[j].data = cadastro.data
                                cadastro.detalheMovimentacao[j].valor = cadastro.valor
                                cadastro.detalheMovimentacao[j].idConta = cadastro.idConta
                            }
                        }
                        else {
                            cadastro.detalheMovimentacao[i].data = cadastro.data
                            cadastro.detalheMovimentacao[i].valor = cadastro.valor
                            cadastro.detalheMovimentacao[i].idConta = cadastro.idConta

                            if ( cadastro.cartao ) {
                                cadastro.detalheMovimentacao[i].faturaMes = this.state.fatura.month
                                cadastro.detalheMovimentacao[i].faturaAno = this.state.fatura.year
                            }

                            break
                        }
                    }
                }

                if ( !achou ) {
                    //Criar a movimentacao
                    //TODO
                }

                if ( !this.state.atualizarProximos ) {
                    cadastro.valor = this.props.movimentacao.valor
                    cadastro.idConta = this.props.movimentacao.idConta
                    cadastro.cartao = this.props.movimentacao.cartao
                }

                if ( this.props.movimentacao.parcelado ) {
                    cadastro.valor = this.props.movimentacao.valor
                    cadastro.idConta = this.props.movimentacao.idConta
                    cadastro.dataFinal = this.props.movimentacao.dataFinal
                }
                cadastro.data = this.props.movimentacao.data
            }
            
            axios.put(Utils.getUrl()+"movimentacoes/",{...cadastro}).then(
                resp => {
                    if ( resp.status === 201 || resp.status === 200) {                    
                        this.setState({ 
                            saving: false,
                            showMessage: false,
                            showErroMessage:false,
                            showEditMessage: false})

                        this.props.onEdit()
                    }
                    else {
                        this.setState({showErroMessage:true,saving: false})
                    }
                }
            ).catch( e => {            
                this.setState({showErroMessage:true,saving: false})
            })                      
        }
        else { //Novo

            axios.post(Utils.getUrl()+"movimentacoes/",{...cadastro}).then(
                resp => {
                    if ( resp.status === 201 || resp.status === 200) {                    
                        this.setState({showMessage:true})

                        this.setState( {
                            cadastro: {
                                descricao: '',
                                valor: 0,
                                idConta: '',
                                idCategoria: '',
                                recorrencia: '',
                                parcelas: '2',
                                tipoRecorrenciaFixa: 'Mensal',
                                data: new Date()
                            },
                            fatura: DateTime.now(),
                            icon: '',
                            iconBgColor: '',
                            saving: false
                        })  
                        
                        this.setState({   
                            saving: false,         
                            showMessage: false,
                            showErroMessage:false,
                            showEditMessage: false
                        })
                        this.props.onSave()
                    }
                    else {
                        this.setState({showErroMessage:true,saving: false})
                    }
                }
            ).catch( e => {            
                this.setState({showErroMessage:true,saving: false})
            })
        }
    }
    
    handleCloseMessage() {
        this.setState({showMessage:false})
    }

    onRendered() {

        if ( this.props.movimentacao._id) {

            let e = this.props.movimentacao
            let data = e.data
            let valor = e.valor
            let idConta = e.idConta

            if ( e.recorrente ) {
                
                if ( e.detalheMovimentacao !== null && e.detalheMovimentacao.length > 0 ) {
                    let m = this.props.mes.month
                    let y = this.props.mes.year
    
                    for(let i=0; i<e.detalheMovimentacao.length; i++) {
                        if ( e.detalheMovimentacao[i].mes === m &&
                            e.detalheMovimentacao[i].ano === y ) { 

                           data = e.detalheMovimentacao[i].data
                           valor = e.detalheMovimentacao[i].valor
                           if ( e.detalheMovimentacao[i].idConta ) idConta = e.detalheMovimentacao[i].idConta
                           else e.detalheMovimentacao[i].idConta = idConta
                           break
                        }
                    }
                }
            }

            this.setState( {
                cadastro: {
                    _id: e._id,
                    descricao: e.descricao,
                    valor: valor,
                    idConta: idConta,
                    idCategoria: e.idCategoria,
                    recorrencia: e.recorrencia,
                    parcelas: e.recorrencia === 'fixo' ?
                        2:e.parcelas,
                    tipoRecorrenciaFixa: e.tipoRecorrenciaFixa,
                    data: data,
                    ativo: e.ativo,
                    detalheMovimentacao: e.detalheMovimentacao
                },
                atualizarProximos: false
            }) 
            
            
        }
        else {
            this.setState( {
                cadastro: {
                    descricao: '',
                    valor: 0,
                    idConta: '',
                    idCategoria: '',
                    recorrencia: '',
                    parcelas: '2',
                    tipoRecorrenciaFixa: 'Mensal',
                    data: new Date()
                },
                fatura: DateTime.now(),
                icon: '',
                iconBgColor: '',
                atualizarProximos: false
            }) 
        }

        this.setState({categoriasReceita: JSON.parse(sessionStorage.getItem('categoriasReceita'))})
        this.setState({categoriasDespesa: JSON.parse(sessionStorage.getItem('categoriasDespesa'))})

        if ( this.props.tipo === 'Receita' )
            this.setState({dialogTitle: 'Receita', listaCategoria: JSON.parse(sessionStorage.getItem('categoriasReceita'))})

        else if ( this.props.tipo === 'Despesa' )
            this.setState({dialogTitle: 'Despesa', listaCategoria: JSON.parse(sessionStorage.getItem('categoriasDespesa'))})

        this.setState({listaCartoes: JSON.parse(sessionStorage.getItem('listaCartoes'))})
        this.setState({listaContas: JSON.parse(sessionStorage.getItem('listaContas'))})
    }

    getLogo() {

        if ( this.state.listaCategoria.length  === 0 )
            return <Avatar 
                style={{
                    backgroundColor: 'green',
                    width: '50px',
                    height: '50px',
                }} >
                    <Icon>account_balance</Icon>
            </Avatar>
        else if ( this.state.cadastro.idCategoria ) {
            for( var i=0; i<this.state.listaCategoria.length; i++) {
                if ( this.state.listaCategoria[i]._id === this.state.cadastro.idCategoria ) {
                    return <Avatar 
                        style={{
                            backgroundColor: this.state.listaCategoria[i].cor,
                            width: '50px',
                            height: '50px',
                        }}>
                        <Icon>{this.state.listaCategoria[i].icone}</Icon>
                    </Avatar>
                }
            }
        }
    }

    
    editFragment() {

        if ( this.props.movimentacao._id && this.props.movimentacao.recorrente ) {
            return (
                <FormControlLabel
                    control={
                    <Switch
                        value={this.state.atualizarProximos}
                        name="proximos"
                        color="Primary"
                        onChange={e => this.setState({atualizarProximos: e.target.checked})}
                    />
                    }
                    label="Atualizar os próximos?"
                />
            )
        }
        else return
    }


    render() {

        return (
            <Dialog 
                open={this.props.open}
                onClose={this.handleClose}
                onRendered={this.onRendered}>

                        <DialogTitle>{this.state.dialogTitle}</DialogTitle>

                        <DialogContent> 
                            <Grid container justifyContent="center">                                                           
                                {this.getLogo()}
                            </Grid>

                            <Box m={3} />                           
                            <MuiPickersUtilsProvider utils={LuxonUtils}>

                            <Grid container spacing={3} alignItems="flex-start">

                                <Grid item xs={12}>
                                    <TextField id="descricao" 
                                        required 
                                        variant='outlined'
                                        error={this.state.descricao}
                                        label="Descrição"   
                                        InputLabelProps={{
                                            shrink: true,
                                        }}           
                                        fullWidth
                                        value={this.state.cadastro.descricao}
                                        onChange={ (e) => 
                                            this.setState( { cadastro: {...this.state.cadastro, 
                                            descricao: e.target.value} } ) }/>
                                </Grid>


                                <Grid item xs={12} md={6}>
                                    <TextField id="valor" 
                                        label="Valor"
                                        margin="normal"
                                        type="number"
                                        variant='outlined'
                                        InputLabelProps={{
                                            shrink: true,
                                            startadornment: <InputAdornment position="start">R$</InputAdornment>
                                        }} 
                                        fullWidth
                                        value={this.state.cadastro.valor}
                                        onChange={ (e) => 
                                            this.setState( { cadastro: {...this.state.cadastro, 
                                            valor: e.target.value} } ) }/>
                                </Grid>


                                <Grid item xs={12} md={6}>
                                    
                                        <KeyboardDatePicker
                                            fullWidth
                                            required
                                            format="dd/MM/yyyy"
                                            margin="normal"
                                            id="data"
                                            label="Data" 
                                            variant='inline'
                                            inputVariant='outlined'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}     
                                            value={this.state.cadastro.data}
                                            onChange={ (e) => {

                                                if ( this.state.cadastro.cartao ) {
                                                    let day = new Date(e).getDate()
                                                    let corte = this.state.dataCorte

                                                    let fatura = day <= corte ? DateTime.fromJSDate(new Date(e))
                                                                        .set({ day: 1 })                                                    
                                                                        .plus({ month: 1 }) :
                                                                    DateTime.fromJSDate(new Date(e))
                                                                    .set({ day: 1 })
                                                                    .plus({ month: 2 })

                                                    let listaFatura = [
                                                        fatura.minus({ month: 1} ),
                                                        fatura,                                                        
                                                        fatura.plus({ month: 1} ),                                    
                                                        fatura.plus({ month: 2} ),
                                                    ]
                                                    this.setState({listaFatura:listaFatura, fatura: fatura})
                                                }
                                                
                                                this.setState( 
                                                    { 
                                                        cadastro: {...this.state.cadastro, 
                                                            data: new Date(e)} 
                                                    }
                                                )}
                                            }
                                        />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <InputLabel id="conta-label" shrink>Conta</InputLabel>
                                    <Select
                                        fullWidth
                                        variant='outlined'
                                        labelId="conta-label"
                                        id="conta-select"
                                        value={this.state.cadastro.idConta}
                                        onChange={ e => {
                                                let cartao = false
                                                let id = e.target.value

                                                for ( var i=0; i<this.state.listaCartoes.length; i++ ) {
                                                    if ( id === this.state.listaCartoes[i]._id ) {
                                                        cartao = true

                                                        let dataCadastro = new Date(this.state.cadastro.data)

                                                        let corte = this.state.listaCartoes[i].corteFatura
                                                        let day = dataCadastro.getDate()

                                                        let fatura = day <= corte ? DateTime.fromJSDate(dataCadastro)
                                                                            .set({ day: 1 })
                                                                            .plus({ month: 1 }) :
                                                                        DateTime.fromJSDate(dataCadastro)
                                                                        .set({ day: 1 })
                                                                        .plus({ month: 2 })

                                                        let listaFatura = [
                                                            fatura.minus({ month: 1} ),
                                                            fatura,                                                        
                                                            fatura.plus({ month: 1} ),                                    
                                                            fatura.plus({ month: 2} ),
                                                        ]
                                                        this.setState({listaFatura:listaFatura, fatura: fatura})
                                                        
                                                        this.setState({dataCorte: corte})
                                                        break
                                                    }
                                                }
                                                
                                                this.setState({
                                                    cadastro: {
                                                        ...this.state.cadastro, 
                                                        idConta: id,
                                                        cartao: cartao
                                                    }
                                                })
                                            }
                                        }>    
                                        <ListSubheader>Contas</ListSubheader>                                    
                                        {
                                            this.state.listaContas.map( (e) => 
                                                <MenuItem value={e._id}>
                                                    {e.apelido}
                                                </MenuItem>
                                            )
                                        }   
                                        <ListSubheader>Cartões</ListSubheader>                                    
                                        {
                                            this.state.listaCartoes.map( (e) => 
                                                <MenuItem value={e._id}>
                                                    {e.apelido}
                                                </MenuItem>
                                            )
                                        }
                                    </Select>
                                    
                                    <Typography variant='subtitle2' hidden={!this.state.cadastro.cartao}>
                                        Fatura de 
                                        <Select 
                                            style={{marginLeft: '5px'}}
                                            value={this.state.fatura}
                                            onChange={
                                                e => this.setState({fatura: e.target.value})
                                            }>
                                            {
                                                this.state.listaFatura.map( (e) => 
                                                    <MenuItem value={e}>
                                                        {e.setLocale('pt').toFormat("MMMM 'de' yyyy")}
                                                    </MenuItem>
                                                )
                                            }
                                        </Select>
                                    </Typography>                                    
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <InputLabel id="categoria-label" shrink>Categoria</InputLabel>
                                    <Select
                                        disabled={this.props.movimentacao && this.props.movimentacao._id && this.props.movimentacao.recorrente}
                                        fullWidth
                                        variant='outlined'
                                        labelId="categoria-label"
                                        id="categoria-select"
                                        value={this.state.cadastro.idCategoria}
                                        onChange={ e => this.setState(
                                            {
                                                cadastro: {
                                                    ...this.state.cadastro, 
                                                    idCategoria: e.target.value
                                                },
                                            }
                                        )}>                                        
                                        {
                                            this.state.listaCategoria.map( (e) => 
                                                <MenuItem value={e._id}>
                                                    {e.descricao}
                                                </MenuItem>
                                            )
                                        }
                                    </Select>                                 
                                </Grid>
                            </Grid>
                            <Box m={2} />

                            <RadioGroup name="fixa" value={this.state.cadastro.recorrencia}
                                onChange={ (e) => {
                                    this.setState({
                                        cadastro: {
                                            ...this.state.cadastro,
                                            recorrencia: e.target.value
                                        }
                                    })
                                }}
                                variant="dense"
                                row>
                                <FormControlLabel value="fixo" control={<Radio disabled={this.props.movimentacao._id} />} label="Lançamento Fixo" classes={{ label: 'MuiTypography-subtitle2 MuiTypography-colorTextSecondary' }}/>
                                <FormControlLabel value="parcelado" control={<Radio disabled={this.props.movimentacao._id}/>} label="Lançamento Parcelado" classes={{ label: 'MuiTypography-subtitle2 MuiTypography-colorTextSecondary' }}/>
                            </RadioGroup>

                            <Grid container spacing={2}>        
                                <Grid item xs={12} hidden={this.state.cadastro.recorrencia !== "fixo"}>
                                    <Select
                                        value={this.state.cadastro.tipoRecorrenciaFixa}
                                        onChange={ (e) => this.setState(
                                            {
                                                cadastro: {
                                                    ...this.state.cadastro,
                                                    tipoRecorrenciaFixa: e.target.value
                                                }
                                            }
                                        )}
                                        disabled={this.props.movimentacao._id}
                                        fullWidth
                                        variant='outlined'
                                        id="mensal-select">                                        
                                        {
                                            ['Mensal'].map( (e) => 
                                                <MenuItem value={e} key={e}>
                                                    {e}
                                                </MenuItem>
                                            )
                                        }
                                    </Select>    
                                </Grid>        
                                <Grid item xs={12} hidden={this.state.cadastro.recorrencia !== "parcelado"}>
                                    
                                    <Select
                                        value={this.state.cadastro.parcelas}
                                        onChange={ (e) => this.setState(
                                            {
                                                cadastro: {
                                                    ...this.state.cadastro,
                                                    parcelas: e.target.value
                                                }
                                            }
                                        )}
                                        fullWidth
                                        disabled={this.props.movimentacao._id}
                                        variant='outlined'
                                        id="parcela-select">                                        
                                        {
                                            ['2','3','4','5','6','7','8','9','10',
                                            '11','12','13','14','15','16','17','18','19','20',
                                            '21','22','23','24','25','26','27','28','29','30',
                                            '31','32','33','34','35','36','37','38','39','40'].map( (e) => 
                                                <MenuItem value={e}>
                                                    {e}
                                                </MenuItem>
                                            )
                                        }
                                    </Select>
                                    <Typography variant='subtitle2' hidden={this.props.movimentacao._id}>
                                        Serão lançadas {this.state.cadastro.parcelas} parcelas de {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.state.cadastro.valor/this.state.cadastro.parcelas)}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                        {this.editFragment()}
                                </Grid>
                            </Grid>

                            <Box m={3} />
                            </MuiPickersUtilsProvider>
                        </DialogContent>
                        
                        <DialogActions style={{justifyContent: 'center'}}>

                            <Link onClick={() => this.setState({confirmDelete: true})} color="secondary"
                                hidden={!this.props.movimentacao._id}  style={{cursor: 'pointer'}}> 
                                Excluir
                            </Link>

                            <Fab color="primary" aria-label="add" label="Salvar">
                                <Check onClick={this.handleSave}/>
                            </Fab>

                            <Link onClick={this.handleClose} color="textSecondary" style={{cursor: 'pointer'}}>
                                Cancelar
                            </Link>

                        </DialogActions>
                        <Box m={1}/>

                        <Snackbar open={this.state.showMessage} autoHideDuration={6000} 
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={this.handleCloseMessage}>
                            <MuiAlert severity="success" elevation={6} variant="filled" onClose={this.handleCloseMessage}>
                                Cadastro realizado com Sucesso!
                            </MuiAlert>
                        </Snackbar>

                        <Snackbar open={this.state.showEditMessage} autoHideDuration={6000} 
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
                            onClose={ () => this.setState({showEditMessage:false}) }>
                            <MuiAlert severity="success" elevation={6} variant="filled" 
                                onClose={() => this.setState({showEditMessage:false})}>
                                Cadastro atualizado com Sucesso!
                            </MuiAlert>
                        </Snackbar>

                        <Snackbar open={this.state.showErroMessage} autoHideDuration={6000} 
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() => this.setState({showErroMessage:false})}>
                            <MuiAlert severity="error" elevation={6} variant="filled" onClose={ () => this.setState({showErroMessage:false})}>
                                Erro ao realizar Cadastro!
                            </MuiAlert>
                        </Snackbar>

                        <ConfirmDialog open={this.state.confirmDelete}
                            handleClose={ () => this.setState({confirmDelete: false}) }
                            handleOk={this.handleDelete}
                            message="Você deseja realmente excluir?"
                            />

                        <Backdrop
                            style={{ color: '#fff', zIndex: 10000 }}
                            open={this.state.saving}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
            </Dialog>
        )
    }
}