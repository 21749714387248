
const moneyFormat = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  });


export class Utils {

    static getUrl() {
        return 'https://api-dot-sigmabills.rj.r.appspot.com/' 
        //return 'http://localhost:8080/'
    }

    static parseData(valor) {
        var valores = valor.split('/')
        var ano = valores[2]
        var mes = valores[1]
        var dia = valores[0]
    
        return new Date(ano+'-'+mes+'-'+dia+'T00:00:00')
    }

    static formatarCPF(cpf){
        //retira os caracteres indesejados...
        cpf = cpf.replace(/[^\d]/g, "");
      
        //realizar a formatação...
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      }

    static formatarData(d) {
        let data = new Date(d)

        let dia = data.getDate()
        if ( dia<10 ) dia = '0'+dia

        let mes = (data.getMonth()+1)
        if ( mes<10 ) mes = '0'+mes


        let datestring =  dia  + "/" + mes + "/" +  data.getFullYear() 
        return datestring
    }

    static formatarDataHora(d) {
        let data = new Date(d)

        let dia = data.getDate()
        if ( dia<10 ) dia = '0'+dia

        let mes = (data.getMonth()+1)
        if ( mes<10 ) mes = '0'+mes

        let hora = data.getHours()
        let min = data.getMinutes()

        if (min < 10) min = '0'+min

        let datestring =  dia  + "/" + mes + "/" +  data.getFullYear() +' '+ hora + ':' +min
        return datestring
    }

    static formatarDinheiro(valor,showMoney) {
        if (showMoney==='false') return ''
        else return moneyFormat.format(valor)
    }

    static formatarPercentual(valor) {
        let result = 0
        if ( valor != null) result = valor
        return result.toFixed(2)+'%'
    }
}