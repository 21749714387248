import React, { Component } from 'react';
import {Box, Grid, TextField, Button, Snackbar, Dialog, DialogTitle} from '@material-ui/core';
import {Avatar, Icon, DialogActions, DialogContent, InputLabel, IconButton } from '@material-ui/core/';
import {Select, MenuItem, Typography, FormControlLabel, Checkbox } from '@material-ui/core/';
import {Backdrop, CircularProgress} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import {Utils} from '../generic/Utils';
import axios from 'axios';
import '../css/Generic.css'


export class CategoriaDialog extends Component {

    constructor(props) {
        super(props)
        this.state = {
            cadastro: {
                userId: '',
                descricao: '',
                icone: '',
                cor: '',
                tipo: 'Receita'
            },
            dialogTitle: 'Nova Categoria',
            selected: '',
            iconBgColor: 'gray',
            icon: '',
            validacaoErro: false,
            showMessage: false,
            showErroMessage:false,
            showEditMessage: false,
            saving: false
        }

        this.handleClose = this.handleClose.bind(this)
        this.handleCloseMessage = this.handleCloseMessage.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.onRendered = this.onRendered.bind(this)
        this.getLogo = this.getLogo.bind(this)
    }

    handleClose() {
        this.setState( {
            cadastro: {
                userId: '',
                descricao: '',
                icone: '',
                tipo: '',
                essencial: false
            },
            selected: '',
            icon: '',
            iconBgColor: 'gray',
            validacaoErro: false,
            showMessage: false,
            showErroMessage:false
        }) 
        this.props.onClose()
    }

    handleSave() {

        let cadastro = {...this.state.cadastro}
        console.log(cadastro.descricao)

        if ( cadastro.descricao === '' ) {
            this.setState({validacaoErro: true})
            this.setState({showErroMessage: true})
            return
        }

        this.setState({saving: true})
        this.setState({validacaoErro: false})
        this.setState({showErroMessage:false})

        cadastro.userId = sessionStorage.getItem('userId')

        cadastro.icone = this.state.icon
        cadastro.cor = this.state.iconBgColor

        if ( typeof this.props.categoria._id !== 'undefined' ) {
            axios.put(Utils.getUrl()+"categorias/",{...cadastro}).then(
                resp => {
                    if ( resp.status === 201 || resp.status === 200) {                    
                        this.setState({showEditMessage:true, saving: false})
                        this.props.onSave()
                    }
                    else {
                        this.setState({showErroMessage:true,saving: false})
                    }
                }
            ).catch( e => {            
                this.setState({showErroMessage:true,saving: false})
            })
        }
        else {
            axios.post(Utils.getUrl()+"categorias/",{...cadastro}).then(
                resp => {
                    if ( resp.status === 201 || resp.status === 200) {                    
                        this.setState({showMessage:true})

                        this.setState( {
                            cadastro: {
                                ...this.state.cadastro,
                                userId: '',
                                descricao: '',
                                icone: '',
                                cor: '',
                                essencial: false
                            },
                            selected: '',
                            iconBgColor: 'gray',
                            icon: '',
                            saving: false
                        })  

                        this.props.onSave()
                    }
                    else {
                        this.setState({showErroMessage:true,saving: false})
                    }
                }
            ).catch( e => {            
                this.setState({showErroMessage:true,saving: false})
            })
        }

    }
    
    handleCloseMessage() {
        this.setState({showMessage:false})
    }

    onRendered() {

        if ( typeof this.props.categoria._id !== 'undefined' ) {
            this.setState( {
                cadastro: {
                    _id: this.props.categoria._id,
                    userId: this.props.categoria.userId,
                    descricao: this.props.categoria.descricao,
                    icone: this.props.categoria.icone,
                    tipo: this.props.categoria.tipo,                    
                    caor: this.props.categoria.cor,
                    essencial: this.props.categoria.essencial
                },
                iconBgColor: this.props.categoria.cor,
                icon: this.props.categoria.icone,
                dialogTitle: 'Editar Categoria'
            })            
        }
        else {
            this.setState( {
                cadastro: {
                    userId: '',
                    descricao: '',
                    icone: '',
                    tipo: 'Receita',
                    cor: '',
                    essencial: false
                },
                selected: '',
                dialogTitle: 'Nova Categoria',
                iconBgColor: 'gray',
                icon: '',
            })   
        }
    }

    getLogo() {

        if ( this.state.icon === '')
            return <Avatar 
            style={{
                backgroundColor: this.state.iconBgColor,
                width: '50px',
                height: '50px',
            }} 
            src='./logo/none.png'/>
        else
            return <Avatar 
                style={{
                    backgroundColor: this.state.iconBgColor,
                    width: '50px',
                    height: '50px',
                }}>
                <Icon>{this.state.icon}</Icon>
            </Avatar>
    }

    render() {

        return (
            <Dialog 
                open={this.props.open}
                onClose={this.handleClose}
                onRendered={this.onRendered}>

                        <DialogTitle>{this.state.dialogTitle}</DialogTitle>

                        <DialogContent>
                            <Grid container justifyContent="center">                                                           
                                {this.getLogo()}
                            </Grid>

                            <Box m={3} />

                            <Grid container spacing={3}  alignItems="flex-end">

                                <Grid item xs={12}>
                                    <TextField id="descricao" 
                                        required 
                                        variant='outlined'
                                        error={this.state.validacaoErro}
                                        label="Descrição"   
                                        InputLabelProps={{
                                            shrink: true,
                                        }}           
                                        fullWidth
                                        value={this.state.cadastro.descricao}
                                        onChange={ (e) => 
                                            this.setState( { cadastro: {...this.state.cadastro, 
                                            descricao: e.target.value} } ) }/>
                                </Grid>

                                <Grid item xs={this.state.cadastro.tipo === "Receita" ? 12:8}>
                                    <InputLabel htmlFor="select" required shrink>
                                        Tipo
                                    </InputLabel>
                                    <Select fullWidth
                                        required
                                        id="select"  
                                        variant='outlined'    
                                        value={this.state.cadastro.tipo}                             
                                        onChange={ (e) =>
                                            this.setState(                                                 
                                                { 
                                                    cadastro: 
                                                    {
                                                        ...this.state.cadastro, 
                                                        tipo: e.target.value
                                                    } 
                                                } ) }>
                                            
                                            <MenuItem value="Receita">
                                                <Typography>Receita</Typography>
                                            </MenuItem>
                                            <MenuItem value="Despesa">
                                                <Typography>Despesa</Typography>
                                            </MenuItem>
                                    </Select>
                                </Grid>

                                <Grid item xs={4} hidden={this.state.cadastro.tipo === "Receita"}>
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                            checked={this.state.cadastro.essencial}
                                            onChange={e => this.setState({
                                                cadastro: 
                                                {
                                                    ...this.state.cadastro, 
                                                    essencial: e.target.checked
                                                }
                                            })}
                                            name="essencial"
                                            color="primary"
                                        />
                                        }
                                        label="Essecial"
                                    />
                                </Grid>
                                
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>                                        
                                        <Grid item xs={12}>
                                            <InputLabel id="cor" shrink>Escolha uma cor</InputLabel>
                                        </Grid>
                                        
                                        {
                                            [
                                                'gray', '#000000', '#6A350C',
                                                '#673799', '#D60D93', '#45A1E4',
                                                '#00C500','#07AB59','#F1DB6D',
                                                '#F58A22','#EB1D2B','#9F0000',
                                            ].map( (e) =>                                                 
                                                <Grid item xs={2} onClick={() => this.setState({iconBgColor: e})}>
                                                    <Avatar style={{backgroundColor: e}} src='./logo/none.png'/>                                            
                                                </Grid>
                                            )
                                        } 
                                    </Grid>
                                </Grid>

                                 
                                <Grid item xs={12} hidden={this.state.cadastro.tipo !== "Receita"}>
                                    <Grid container spacing={2}>                                        
                                        <Grid item xs={12}>
                                            <InputLabel id="cor" shrink>Escolha um ícone</InputLabel>
                                        </Grid>
                                        {
                                            [
                                                'monetization_on_outlined', 'local_atm', 'account_balance_outlined',
                                                'payment_outlined', 'attach_money_outlined', 'thumb_up_outlined',
                                                'account_balance_wallet_outlined','add_shopping_cart_outlined','alarm_on_outlined',
                                                'archive_outlined','beenhere_outlined','redeem',
                                            ].map( (e) => 
                                                <Grid item xs={2} onClick={() => this.setState({icon: e})}>
                                                    <IconButton>
                                                        <Icon>{e}</Icon>
                                                    </IconButton>                                      
                                                </Grid>
                                            )
                                        }                                         
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} hidden={this.state.cadastro.tipo !== "Despesa"}>
                                    <Grid container spacing={2}>                                        
                                        <Grid item xs={12}>
                                            <InputLabel id="cor" shrink>Escolha um ícone</InputLabel>
                                        </Grid>
                                        {
                                            [
                                                'local_offer_outlined', 'people_outline_outlined', 'shopping_cart_outlined',
                                                'local_hospital_outlined','create_outlined', 'receipt_outlined',
                                                'house_outlined', 'local_gas_station_outlined', 'drive_eta_outlined',
                                                'airplanemode_active_outlined', 'subscriptions_outlined', 'sports_esports_outlined'
                                            ].map( (e) => 
                                                <Grid item xs={2} onClick={() => this.setState({icon: e})}>
                                                    <IconButton>
                                                        <Icon>{e}</Icon>
                                                    </IconButton>                                      
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Box m={3} />
                        </DialogContent>
                        
                        <DialogActions>
                            <Button variant="contained" color="primary" onClick={this.handleSave}>
                                Salvar
                            </Button>

                            <Button variant="outlined" color="default" onClick={this.handleClose}>
                                Cancelar
                            </Button>
                        </DialogActions>

                        <Snackbar open={this.state.showMessage} autoHideDuration={6000} 
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
                            onClose={this.handleCloseMessage}>
                            <MuiAlert severity="success" elevation={6} variant="filled" 
                                onClose={this.handleCloseMessage}>
                                Cadastro realizado com Sucesso!
                            </MuiAlert>
                        </Snackbar>

                        <Snackbar open={this.state.showEditMessage} autoHideDuration={6000} 
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
                            onClose={ () => this.setState({showEditMessage:false}) }>
                            <MuiAlert severity="success" elevation={6} variant="filled" 
                                onClose={() => this.setState({showEditMessage:false})}>
                                Cadastro atualizado com Sucesso!
                            </MuiAlert>
                        </Snackbar>

                        <Snackbar open={this.state.showErroMessage} autoHideDuration={6000} 
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
                            onClose={() => this.setState({showErroMessage:false})}>
                            <MuiAlert severity="error" elevation={6} variant="filled" 
                                onClose={ () => this.setState({showErroMessage:false})}>
                                Erro ao realizar Cadastro!
                            </MuiAlert>
                        </Snackbar>

                        <Backdrop
                            style={{ color: '#fff', zIndex: 10000 }}
                            open={this.state.saving}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
            </Dialog>
        )
    }
}