import React, { Component } from 'react';
import {Box, Grid, TextField, Button, Snackbar, Dialog, DialogTitle} from '@material-ui/core';
import {Avatar, MenuItem, Select, DialogActions, DialogContent, InputLabel} from '@material-ui/core/';
import {Backdrop, CircularProgress, Typography} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import CreditCardIcon from '@material-ui/icons/CreditCard';

import {Utils} from '../generic/Utils';
import axios from 'axios';
import '../css/Generic.css'

export class CartaoDialog extends Component {

    constructor(props) {
        super(props)
        this.state = {
            cadastro: {
                userId: '',
                instituicao: '',
                apelido: '',
                icone: '',
                vencimento: 5,
                corteFatura: 25
            },
            diasVencimento: [1,2,3,4,5,6,7,8,9,10,
                11,12,13,14,15,16,17,18,19,20,
                21,22,23,24,25,26,27,28,29,30],
            icones: [
                {
                    instituicao: 'Banco do Brasil',
                    icone: '/logo/bb.png',
                },
                {
                    instituicao: 'Bradesco',
                    icone: '/logo/bradesco.png',
                },
                {
                    instituicao: 'C6 Bank',
                    icone: '/logo/c6.png',
                },
                {
                    instituicao: 'Caixa',
                    icone: '/logo/caixa.png',
                },
                {
                    instituicao: 'Itaú',
                    icone: '/logo/itau.png',
                },
                {
                    instituicao: 'Mastercard',
                    icone: '/logo/mastercard.png',
                },
                {
                    instituicao: 'Nu Bank',
                    icone: '/logo/nuconta.png',
                },
                {
                    instituicao: 'Santander',
                    icone: '/logo/santander.png',
                },
                {
                    instituicao: 'Visa',
                    icone: '/logo/visa.png',
                },
            ],
            dialogTitle: 'Novo Cartão de Crédito',
            selected: '',
            instituicaoErro: false,
            apelidoErro: false,
            showMessage: false,
            showErroMessage:false,
            showEditMessage: false,
            saving: false
        }

        this.handleClose = this.handleClose.bind(this)
        this.handleCloseMessage = this.handleCloseMessage.bind(this)
        this.handleSave = this.handleSave.bind(this)

        this.onRendered = this.onRendered.bind(this)
    }

    handleClose() {
        this.setState( {
            cadastro: {
                userId: '',
                instituicao: '',
                apelido: '',
                icone: '',
                vencimento: 5,
                corteFatura: 25
            },
            selected: '',
            instituicaoErro: false,
            apelidoErro: false,
            showMessage: false,
            showErroMessage:false
        }) 
        this.props.onClose()
    }

    handleSave() {

        let cadastro = {...this.state.cadastro}

        if ( cadastro.instituicao.trim() === '' ) {
            this.setState({instituicaoErro: true})
            this.setState({showErroMessage:true})
            return
        }

        if ( cadastro.apelido.trim() === '' ) {
            this.setState({apelidoErro: true})
            this.setState({showErroMessage:true})
            return
        }

        this.setState({saving: true})
        this.setState({instituicaoErro: false})
        this.setState({apelidoErro: false})
        this.setState({showErroMessage:false})

        cadastro.userId = sessionStorage.getItem('userId')

        if ( typeof this.props.conta._id !== 'undefined' ) {
            axios.put(Utils.getUrl()+"cartoes/",{...cadastro}).then(
                resp => {
                    if ( resp.status === 201 || resp.status === 200) {                    
                        this.setState({showEditMessage:true, saving: false})
                        this.props.onSave()
                    }
                    else {
                        this.setState({showErroMessage:true,saving: false})
                    }
                }
            ).catch( e => {            
                this.setState({showErroMessage:true,saving: false})
            })
        }
        else {
            axios.post(Utils.getUrl()+"cartoes/",{...cadastro}).then(
                resp => {
                    if ( resp.status === 201 || resp.status === 200) {                    
                        this.setState({showMessage:true})

                        this.setState( {
                            cadastro: {
                                userId: '',
                                instituicao: '',
                                apelido: '',
                                icone: '',
                                vencimento: 5,
                                corteFatura: 25
                            },
                            selected: '',
                            saving: false
                        })  

                        this.props.onSave()
                    }
                    else {
                        this.setState({showErroMessage:true,saving: false})
                    }
                }
            ).catch( e => {            
                this.setState({showErroMessage:true,saving: false})
            })
        }

    }
    
    handleCloseMessage() {
        this.setState({showMessage:false})
    }

    getLogo(icon) {
        if ( icon === '')
            return  <Avatar style={{ width: 55, height: 55 }} variant="rounded">                                
                        <CreditCardIcon /> 
                    </Avatar>
        else
            return <Avatar src={icon} style={{ width: 55, height: 55 }} variant="rounded"/>
    }

    onRendered() {

        if ( typeof this.props.conta._id !== 'undefined' ) {
            this.setState( {
                cadastro: {
                    _id: this.props.conta._id,
                    userId: this.props.conta.userId,
                    instituicao: this.props.conta.instituicao,
                    apelido: this.props.conta.apelido,
                    icone: this.props.conta.icone,
                    vencimento: this.props.conta.vencimento,
                    corteFatura: this.props.conta.corteFatura
                },
                dialogTitle: 'Editar Cartão de Crédito'
            })

            this.state.icones.forEach( value => {
                if ( value.instituicao === this.props.conta.instituicao )
                    this.setState({selected: value})
            });

            
        }
        else {
            this.setState( {
                cadastro: {
                    userId: '',
                    instituicao: '',
                    apelido: '',
                    icone: '',
                    vencimento: 5,
                    corteFatura: 25
                },
                selected: '',
                dialogTitle: 'Novo Cartão de Crédito'
            })   
        }
    }


    render() {

        return (
            <Dialog 
                open={this.props.open}
                onClose={this.handleClose}
                onRendered={this.onRendered}>

                        <DialogTitle>{this.state.dialogTitle}</DialogTitle>

                        <DialogContent>

                            <Grid container justifyContent="center" >                                                           
                                {this.getLogo(this.state.cadastro.icone)}
                            </Grid>
                            <Box m={3} />

                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12}>
                                    <InputLabel htmlFor="select" required shrink>
                                        Instituição
                                    </InputLabel>
                                    <Select fullWidth
                                        required
                                        variant='outlined'
                                        error={this.state.instituicaoErro}
                                        id="select"      
                                        value={this.state.selected}                             
                                        onChange={ (e) =>
                                            this.setState(                                                 
                                                { 
                                                    selected: e.target.value,
                                                    cadastro: 
                                                    {
                                                        ...this.state.cadastro, 
                                                        instituicao: e.target.value.instituicao,
                                                        icone:  e.target.value.icone
                                                    } 
                                                } ) }>

                                            {
                                                this.state.icones.map( (b) => 
                                                    <MenuItem value={b}>
                                                        <Typography>{b.instituicao}</Typography>
                                                    </MenuItem>
                                                )
                                            }

                                    </Select>
                                </Grid>


                                <Grid item xs={12} md={12}>
                                    <TextField id="apelido" 
                                        required 
                                        variant='outlined'
                                        error={this.state.apelidoErro}
                                        label="Nome"   
                                        InputLabelProps={{
                                            shrink: true,
                                        }}           
                                        helperText="Informe um nome para identificar seu cartão."
                                        fullWidth
                                        value={this.state.cadastro.apelido}
                                        onChange={ (e) => 
                                            this.setState( { cadastro: {...this.state.cadastro, 
                                            apelido: e.target.value} } ) }/>
                                </Grid>


                                <Grid item xs={12} md={12}>
                                    <TextField id="vencimento" 
                                        label="Dia do Vencimento"
                                        select
                                        variant='outlined'
                                        InputLabelProps={{
                                            shrink: true
                                        }} 
                                        fullWidth
                                        value={this.state.cadastro.vencimento}
                                        onChange={ (e) => 
                                            this.setState( { cadastro: {...this.state.cadastro, 
                                            vencimento: e.target.value} } ) }>

                                        {this.state.diasVencimento.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}

                                    </TextField>
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <TextField id="melhorDia" 
                                        label="Dia de Corte"
                                        select
                                        variant='outlined'
                                        InputLabelProps={{
                                            shrink: true
                                        }} 
                                        fullWidth
                                        value={this.state.cadastro.corteFatura}
                                        onChange={ (e) => 
                                            this.setState( { cadastro: {...this.state.cadastro, 
                                                corteFatura: e.target.value} } ) }>

                                        {this.state.diasVencimento.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}

                                    </TextField>
                                </Grid>
                            </Grid>
                            <Box m={3} />
                        </DialogContent>
                        
                        <DialogActions>
                            <Button variant="contained" color="primary" onClick={this.handleSave}>
                                Salvar
                            </Button>

                            <Button variant="outlined" color="default" onClick={this.handleClose}>
                                Cancelar
                            </Button>
                        </DialogActions>

                        <Snackbar open={this.state.showMessage} autoHideDuration={6000} 
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
                            onClose={this.handleCloseMessage}>
                            <MuiAlert severity="success" elevation={6} variant="filled" 
                                onClose={this.handleCloseMessage}>
                                Cadastro realizado com Sucesso!
                            </MuiAlert>
                        </Snackbar>

                        <Snackbar open={this.state.showEditMessage} autoHideDuration={6000} 
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
                            onClose={ () => this.setState({showEditMessage:false}) }>
                            <MuiAlert severity="success" elevation={6} variant="filled" 
                                onClose={() => this.setState({showEditMessage:false})}>
                                Cadastro atualizado com Sucesso!
                            </MuiAlert>
                        </Snackbar>

                        <Snackbar open={this.state.showErroMessage} autoHideDuration={6000} 
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
                            onClose={() => this.setState({showErroMessage:false})}>
                            <MuiAlert severity="error" elevation={6} variant="filled" 
                                onClose={ () => this.setState({showErroMessage:false})}>
                                Erro ao realizar Cadastro!
                            </MuiAlert>
                        </Snackbar>

                        <Backdrop
                            style={{ color: '#fff', zIndex: 10000 }}
                            open={this.state.saving}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
            </Dialog>
        )
    }
}