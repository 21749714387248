import React, { Component } from 'react';
import {Fab, Paper, Grid, Backdrop, CircularProgress, Avatar, IconButton, Box} from '@material-ui/core/';
import {List, ListItem, ListItemText, ListItemAvatar, ListItemSecondaryAction} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import Title from '../generic/Title';
import {ContaDialog} from './ContaDialog';
import {Utils} from '../generic/Utils';
import {ConfirmDialog} from '../util/ConfirmDialog'
import axios from 'axios';

import '../css/Generic.css'
  
export class ListarConta extends Component {

    constructor(props) {
        super(props)

        this.state = {
            showNovoDialog: false,
            loading: true,
            confirmDelete: false,
            selected: {},
            listaContas: [{}]
        }  
        this.handleDelete = this.handleDelete.bind(this)
    }

    componentDidMount() {
        this.buscarListaContas();
    }

    buscarListaContas() {
        let userId = sessionStorage.getItem('userId')        

        this.setState({loading: true})
        axios.get(Utils.getUrl()+"contas/user/"+userId).then(
            resp => {
                if ( resp.status === 201 || resp.status === 200) {
                    let lista = resp.data
                    this.setState({listaContas:lista})
                    sessionStorage.setItem("listaContas",JSON.stringify(lista));
                }
                else console.log("Erro ao buscar contas")

                this.setState({loading: false})
            }
        ).catch( e => {
            console.log("Erro ao buscar contas "+e) 
            this.setState({loading: false})
        })
    }

    handleDelete() {        

        let conta = this.state.selected
        
        this.setState({loading: true})
        axios.delete(Utils.getUrl()+"contas/"+conta._id,).then(
            resp => {
                this.buscarListaContas();
                this.setState({loading: false, confirmDelete: false})
            }
        ).catch( e => {
            console.log("Erro ao deletar conta "+e) 
            this.setState({loading: false, confirmDelete: false})
        })
    }
    
    render() {
        return(
            <Paper className="class-paper">
                <Title>Suas Contas</Title>        
                <Grid container spacing={3}>
                    
                    <List className="fullSizeList">
                    {
                        this.state.listaContas.map( (e) => 
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar src={e.icone} variant="rounded"/>
                                </ListItemAvatar>
                                <ListItemText primary={e.apelido} secondary={e.instituicao}/>
                                <ListItemSecondaryAction>
                                    <IconButton>
                                        <EditOutlinedIcon onClick={ () => this.setState({showNovoDialog: true, selected: e}) }/>   
                                    </IconButton>
                                    <IconButton onClick={ () => this.setState({confirmDelete: true, selected: e}) }>
                                        <DeleteIcon/>   
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        )
                    }
                    </List>

                </Grid>
        
                <div style={{textAlign: "right"}}>
                    <Box m={2} />
                    <Fab color="primary" aria-label="add" onClick={ () => this.setState({showNovoDialog:true, selected: {}}) }>
                        <AddIcon />
                    </Fab>
                </div>

                <ContaDialog open={this.state.showNovoDialog} 
                    conta={this.state.selected}
                    onSave={ () => this.buscarListaContas() }
                    onClose={ () => { 
                        this.setState({showNovoDialog:false}) 
                        this.buscarListaContas()
                    }} />    

                <ConfirmDialog open={this.state.confirmDelete}
                    handleClose={ () => this.setState({confirmDelete: false}) }
                    handleOk={this.handleDelete}
                    message="Você deseja realmente excluir a Conta?"
                    />

                <Backdrop
                    style={{ color: '#fff', zIndex: 10000 }}
                    open={this.state.loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>            
            </Paper>
        )
    }
}