import React, { Component } from 'react';
import {Fab, Paper, Grid, Backdrop, CircularProgress, Avatar, IconButton, Box, Icon} from '@material-ui/core/';
import {List, ListItem, ListItemText, ListItemAvatar, ListItemSecondaryAction} from '@material-ui/core';
import {AppBar, Tabs, Tab, Card, CardContent } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import Title from '../generic/Title';
import {CategoriaDialog} from './CategoriaDialog';
import {Utils} from '../generic/Utils';
import {ConfirmDialog} from '../util/ConfirmDialog'
import axios from 'axios';

import '../css/Generic.css'
  
export class ListarCategoria extends Component {

    constructor(props) {
        super(props)

        this.state = {
            showNovoDialog: false,
            loading: true,
            confirmDelete: false,
            selected: {},
            tabValue: 0,
            lista: [],
            listaDespesa: []
        }  
        this.handleDelete = this.handleDelete.bind(this)
    }

    componentDidMount() {
        this.buscarLista();
    }

    buscarLista() {
        
        let userId = sessionStorage.getItem('userId')        
        this.setState({loading: true})

        axios.get(Utils.getUrl()+"categorias/user/"+userId).then(
            resp => {
                if ( resp.status === 201 || resp.status === 200) {

                    let lista = resp.data
                    let listaReceita = []
                    let listaDespesa = []
                    lista.forEach(e => {
                        if ( e.tipo === "Receita" )
                            listaReceita.push(e)
                        else
                            listaDespesa.push(e)
                    });
                    this.setState({lista: listaReceita})
                    this.setState({listaDespesa: listaDespesa})
                    
                    sessionStorage.setItem("categoriasReceita",JSON.stringify(listaReceita));
                    sessionStorage.setItem("categoriasDespesa",JSON.stringify(listaDespesa));

                }
                else console.log("Erro ao buscar categorias")
                       
                this.setState({loading: false})
            }
        ).catch( e => {
            console.log("Erro ao buscar categorias "+e) 
            this.setState({loading: false})
        })
    }

    handleDelete() {        

        let conta = this.state.selected
        
        this.setState({loading: true})
        axios.delete(Utils.getUrl()+"categorias/"+conta._id,).then(
            resp => {
                this.buscarLista();
                this.setState({loading: false, confirmDelete: false})
            }
        ).catch( e => {
            console.log("Erro ao deletar "+e) 
            this.setState({loading: false, confirmDelete: false})
        })
    }
    
    render() {
        const handleChange = (event, newValue) => {
            this.setState({tabValue: newValue})
        };

        return(
            <Paper className="class-paper">
                <Title>Suas Categorias</Title>   
                <Box m={3} />

                <AppBar position="static" color="default">
                    <Tabs
                        value={this.state.tabValue}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth">
                        <Tab label="Receitas" id="p0"/>
                        <Tab label="Desespesas" id="p1"/>
                    </Tabs>
                </AppBar>

                <div role="tabpanel" hidden={this.state.tabValue !== 0} id="panel-0"> 
                    <Card variant="outlined">
                        <CardContent>
                        <Grid container spacing={3}>                    
                            <List className="fullSizeList">
                            {
                                this.state.lista.map( (e) => 
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar 
                                                style={{
                                                    backgroundColor: e.cor
                                                }}>
                                                <Icon>{e.icone}</Icon>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={e.descricao}/>
                                        <ListItemSecondaryAction>
                                            <IconButton>
                                                <EditOutlinedIcon onClick={ () => this.setState({showNovoDialog: true, selected: e}) }/>   
                                            </IconButton>
                                            <IconButton onClick={ () => this.setState({confirmDelete: true, selected: e}) }>
                                                <DeleteIcon/>   
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )
                            }
                            </List>
                        </Grid>
                        </CardContent>
                    </Card>
                </div>

                <div role="tabpanel" hidden={this.state.tabValue !== 1} id="panel-1">
                    <Card variant="outlined">
                        <CardContent>
                        <Grid container spacing={3}>                    
                            <List className="fullSizeList">
                            {
                                this.state.listaDespesa.map( (e) => 
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar 
                                                style={{
                                                    backgroundColor: e.cor
                                                }}>
                                                <Icon>{e.icone}</Icon>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={e.descricao}/>
                                        <ListItemSecondaryAction>
                                            <IconButton>
                                                <EditOutlinedIcon onClick={ () => this.setState({showNovoDialog: true, selected: e}) }/>   
                                            </IconButton>
                                            <IconButton onClick={ () => this.setState({confirmDelete: true, selected: e}) }>
                                                <DeleteIcon/>   
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )
                            }
                            </List>
                        </Grid>
                        </CardContent>
                    </Card>
                </div>

        
                <div style={{textAlign: "right"}}>
                    <Box m={3} />
                    <Fab color="primary" aria-label="add" onClick={ () => this.setState({showNovoDialog:true, selected: {}}) }>
                        <AddIcon />
                    </Fab>
                </div>

                <CategoriaDialog open={this.state.showNovoDialog} 
                    categoria={this.state.selected}
                    onSave={ () => this.buscarLista() }
                    onClose={ () => { 
                        this.setState({showNovoDialog:false}) 
                        this.buscarLista()
                    }} />    

                <ConfirmDialog open={this.state.confirmDelete}
                    handleClose={ () => this.setState({confirmDelete: false}) }
                    handleOk={this.handleDelete}
                    message="Você deseja realmente excluir?"
                    />

                <Backdrop
                    style={{ color: '#fff', zIndex: 10000 }}
                    open={this.state.loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>    

            </Paper>
        )
    }
}