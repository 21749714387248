import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {Add, Remove} from '@material-ui/icons/';
import {IconButton, Typography, Paper, Grid} from '@material-ui/core';
import {List, ListItem, Icon, Backdrop, CircularProgress} from '@material-ui/core/';
import {Avatar, ListItemText, ListItemAvatar, ListItemSecondaryAction} from '@material-ui/core/';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { Chart } from "react-google-charts";

import '../css/Generic.css'
import Title from '../generic/Title';
import {Utils} from '../generic/Utils';
import {AddTransacaoDialog} from '../transacoes/AddTransacaoDialog'

import { DateTime } from "luxon";
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    minHeight: 265,
  },
}));


export default function Dashboard() {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [receita, setReceita] = useState(0)
  const [despesa, setDespesa] = useState(0)
  const [loading, setLoading] = useState(true)
  const [showNovoDialog, setShowNovoDialog] = useState(false)
  const [dialogTipo, setDialogTipo] = useState('Receita')
  const [proximasDespesas, setProximasDespesas] = useState([])
  const [proximasReceitas, setProximasReceitas] = useState([])

  let userId = sessionStorage.getItem('userId')

  let receitaMensal = 0
  let despesaMensal = 0

  let d = DateTime.now()

  let m = d.month
  if ( m<10 ) m = "0"+m
  let dia = d.day
  if ( dia<10 ) dia = "0"+dia
  let datestring = d.year + "-" + m + "-" + dia        

  
  let categoriasReceita = JSON.parse(sessionStorage.getItem('categoriasReceita'))
  let categoriasDespesa = JSON.parse(sessionStorage.getItem('categoriasDespesa'))

  axios.get(Utils.getUrl()+"movimentacoes/user/"+userId+"/date/"+datestring).then(
      resp => {
          if ( resp.status === 201 || resp.status === 200) {

              let listaMovimentacoes = resp.data
              
              listaMovimentacoes.forEach(e => {

                  e.valorExibicao = e.valor

                  if ( e.recorrente ) {
                      if ( e.detalheMovimentacao === null ) e.detalheMovimentacao = []                      

                      for(let i=0; i<e.detalheMovimentacao.length; i++) {

                          if ( e.detalheMovimentacao[i].mes === d.month &&
                              e.detalheMovimentacao[i].ano === d.year ) {
                                  
                              e.valorExibicao = e.detalheMovimentacao[i].valor   
                              e.data = e.detalheMovimentacao[i].data
                              e.pago = e.detalheMovimentacao[i].pago
                              break
                          }
                      }                        
                  }

                  
                  if ( e.faturaCartao )
                    despesaMensal += e.valorExibicao
                  else if ( e.categoria.tipo === 'Receita' )                                             
                    receitaMensal += e.valorExibicao
                  else if ( e.categoria.tipo === 'Despesa' )
                    despesaMensal += e.valorExibicao

              });

              let proximas = listaMovimentacoes.sort( (a,b) => new Date(a.data) - new Date(b.data) )

              proximas.forEach(e => {

                if ( e.faturaCartao ) return;
                if ( e.categoria.tipo === 'Receita' && proximasReceitas.length<3 && !e.pago ) {                                           
                  
                  for (let i=0; i<categoriasReceita.length; i++) {
                    if ( categoriasReceita[i]._id === e.idCategoria ) {
                        let cor = categoriasReceita[i].cor
                        let icon = categoriasReceita[i].icone
                        let descricao = categoriasReceita[i].descricao

                        e.avatar = (<Avatar alt={descricao}
                                      style={{
                                          backgroundColor: cor,
                                      }} 
                                      variant="rounded">
                                          <Icon>{icon}</Icon>
                                    </Avatar>)
                        break
                    }
                  }
                  
                  proximasReceitas.push(e)
                }
                else if ( e.categoria.tipo === 'Despesa' && proximasDespesas.length<3 && !e.pago )  {    

                  for (let i=0; i<categoriasDespesa.length; i++) {
                    if ( categoriasDespesa[i]._id === e.idCategoria ) {
                        let cor = categoriasDespesa[i].cor
                        let icon = categoriasDespesa[i].icone
                        let descricao = categoriasDespesa[i].descricao

                        e.avatar = (<Avatar alt={descricao}
                                      style={{
                                          backgroundColor: cor,
                                      }} 
                                      variant="rounded">
                                          <Icon>{icon}</Icon>
                                    </Avatar>)
                        break
                    }
                  }     

                  proximasDespesas.push(e)
                }

              })

              setProximasDespesas(proximasDespesas)
              setProximasReceitas(proximasReceitas)
              setReceita(receitaMensal)
              setDespesa(despesaMensal)
              setLoading(false)
          } 
          else {
            console.log("Erro ao buscar lista")
          }
      }
  ).catch( e => {
      console.log("Erro ao buscar "+e) 
  })
  

  return (
      <Grid container spacing={3}>

          <Grid item xs={12} md={12} lg={12}>
              <Typography component="p" variant="h6">
                Bem-vindo, {sessionStorage.getItem('userName')}!
              </Typography>
          </Grid>

          <Grid item xs={12}>
            <Paper className={fixedHeightPaper}>
              <Grid container spacing={3} alignItems="center" justifyContent="center">

                <Grid item xs={6}>  
                  <Title>
                    Balanço do Mês
                  </Title>
                </Grid>

                <Grid item xs={6} style={{textAlign: 'right'}}>                    
                  <IconButton color="primary" title='Nova Receita' onClick={() => {
                    setDialogTipo('Receita')
                    setShowNovoDialog(true)
                  }}>
                      <Add fontSize='large'/>
                  </IconButton>

                  <IconButton color="secondary" title='Nova Despesa' onClick={() => {
                    setDialogTipo('Despesa')
                    setShowNovoDialog(true)
                  }}>
                      <Remove fontSize='large'/>
                  </IconButton>
                  
                </Grid>              

                <Grid item xs={12} md={4}>
                  <Paper elevation={3} style={{padding: '10px'}}>
                    <Typography variant='caption' color='textSecondary'> 
                        Receita Mensal:
                    </Typography>  
                    <Typography variant='h5' color={'primary'} align='right'>
                        {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(receita)}
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Paper elevation={3} style={{padding: '10px'}}>
                    <Typography variant='caption' color='textSecondary'> 
                        Despesa Mensal:
                    </Typography>  
                    <Typography variant='h5' color={'secondary'} align='right'>
                        {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(despesa)}
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Paper elevation={3} style={{padding: '10px'}}>
                    <Typography variant='caption' color='textSecondary'> 
                        Resultado do Mês:
                    </Typography>  
                    <Typography variant='h5' color={receita-despesa>0?'primary':'secondary'} align='right'>
                        {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(receita-despesa)}
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item xs={10}>
                  <Paper elevation={3} style={{padding: '10px'}}>
                    
                    <Chart
                      chartType="BarChart"
                      width="100%"
                      data={[
                          ["Tipo", "Valor",
                            { role: "style" },
                            {
                              sourceColumn: 0,
                              role: "annotation",
                              type: "string",
                              calc: "stringify",
                            },
                          ], 
                          ["Receitas",receita,"#3F51B5",null], 
                          ["Despesas",despesa,"#F50057",null]]}
                      options={
                        {
                          bars: "horizontal",
                          legend: { position: "none" },
                          hAxis: {
                            title: "",
                            minValue: 0
                          },
                          vAxis: {
                            title: "",
                          },
                        }
                      }
                    />
                      
                  </Paper>
                </Grid>

                <Grid item xs={2}>
                  <Chart
                    chartType="PieChart"
                    width="100%"
                    data={[
                      ["", ""],
                      ["",parseInt((despesa/receita)*100)],
                      ["",1-(despesa/receita)>0?parseInt((1-(despesa/receita))*100):0]   
                    ]}
                    options={{
                      legend: { position: "none" },
                      pieSliceText: "none",
                      pieHole: 0.4,
                      is3D: false,                      
                      tooltip: { trigger: "none" },
                      slices: {
                        0: { color: receita-despesa>0?'#3F51B5':'#F50057' },
                        1: { color: "#EBEDED" }, 
                      },
                    }}
                  />
                  <Typography variant='subtitle2' align='center'>
                  {((despesa/receita)*100).toFixed(2)+'%'}
                  </Typography>
                </Grid>
              </Grid>


            </Paper>          
          </Grid>

          <Grid item xs={6}>
            <Accordion expanded={true}>
              <AccordionSummary>                        
                <Title>
                  Próximas despesas
                </Title>
              </AccordionSummary>
              <AccordionDetails>
                <List className="fullSizeList">
                {
                  proximasDespesas.map( e => {
                    return (<ListItem key={'itemKey'+e._id}>
                      <ListItemAvatar>
                          {e.avatar}
                      </ListItemAvatar>

                      <ListItemText primary={e.descricao} 
                          secondary={DateTime.fromISO(e.data).setLocale('pt').toLocaleString()}/>

                      <ListItemSecondaryAction>                          
                        <Typography variant='subtitle2'>
                            {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(e.valorExibicao)}
                        </Typography>
                      </ListItemSecondaryAction>
                  </ListItem>)
                  })
                }
                </List>
              </AccordionDetails>
            </Accordion>
          </Grid>
          
          <Grid item xs={6}>
            <Accordion expanded={true}>
              <AccordionSummary>                        
                <Title>
                  Próximas receitas
                </Title>
              </AccordionSummary>
              <AccordionDetails>
                <List className="fullSizeList">
                {
                  proximasReceitas.map( e => {
                    return (<ListItem key={'itemKey'+e._id}>
                      <ListItemAvatar>
                          {e.avatar}
                      </ListItemAvatar>

                      <ListItemText primary={e.descricao} 
                          secondary={DateTime.fromISO(e.data).setLocale('pt').toLocaleString()}/>

                      <ListItemSecondaryAction>                          
                        <Typography variant='subtitle2'>
                            {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(e.valorExibicao)}
                        </Typography>
                      </ListItemSecondaryAction>
                  </ListItem>)
                  })
                }
                </List>
              </AccordionDetails>
            </Accordion>
          </Grid>
          
          <Backdrop
              style={{ color: '#fff', zIndex: 10000 }}
              open={loading}>
              <CircularProgress color="inherit" />
          </Backdrop>

          <AddTransacaoDialog open={showNovoDialog} 
              mes={DateTime.now()}
              movimentacao={{}}
              tipo={dialogTipo}
              onSave={ () => {
                  setShowNovoDialog(false)
                } }
              onEdit={ () => {
                  setShowNovoDialog(false)
                  setLoading(false)
                } }
              onClose={ () => {
                  setShowNovoDialog(false)
                  setLoading(false)                   
                } } 
              onDelete={ () => {
                  setShowNovoDialog(false)
                  setLoading(false)                       
                } }/>     
      </Grid>
  )
}